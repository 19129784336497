import React from 'react';
import useI18n from '@i18n';
import SectionTitle from '@components/pages/home/epnbz/Base/SectionTitle';
import ContainerWithLayout from '../ContainerWithLayout';

import styles from './BlockPayments.module.scss';

import mastercardImg from './img/mastercard.png';
import tonImg from './img/ton.png';
import visaImg from './img/visa.png';
import webmoneyImg from './img/webmoney.png';

const BlockPayments: React.FC = () => {
    const { t } = useI18n();
    return (
        <section>
            <ContainerWithLayout colorName="bordered">
                <div className={styles.wrapper}>
                    <SectionTitle classname={styles.title}>
                        {t('We pay for traffic in')}
                    </SectionTitle>
                    <div className={styles.icons}>
                        <img src={visaImg} alt="visa" />
                        <img src={mastercardImg} alt="mastercard" />
                        <img src={webmoneyImg} alt="webmoney" />
                        <img src={tonImg} alt="ton" />
                    </div>
                </div>
            </ContainerWithLayout>
        </section>
    );
};

export default BlockPayments;
