import Button from 'epn-ui/umd/dist/components/Button';
import useI18n from '@i18n';

import styles from './RegisterButton.module.scss';

interface IRegisterButtonProps {
    handleRegistration: () => void;
    children?: React.ReactNode;
}

const RegisterButton: React.FC<IRegisterButtonProps> = ({ handleRegistration, children }) => {
    const { t } = useI18n();

    return (
        <Button.Beta
            className={styles.btn}
            size="large"
            type="primary"
            onClick={handleRegistration}
        >
            {children || t('Registration-Sign-up')}
        </Button.Beta>
    );
};

export default RegisterButton;
