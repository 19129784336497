import clsx from 'clsx';
import styles from './ContainerWithLayout.module.scss';

enum bgColorNames {
    black = 'black',
    greyGradient = 'greyGradient',
    bordered = 'bordered',
}
interface IProps {
    colorName: keyof typeof bgColorNames;
    children: React.ReactNode;
}

const ContainerWithLayout: React.FC<IProps> = ({ colorName, children }) => {
    return (
        <div className={styles.container}>
            <div
                className={clsx(styles.container_layout, {
                    [styles.container_layout__black]: colorName === bgColorNames.black,
                    [styles.container_layout__greyGradient]:
                        colorName === bgColorNames.greyGradient,
                    [styles.container_layout__bordered]: colorName === bgColorNames.bordered,
                })}
            >
                <div className={styles.container_children}>{children}</div>
            </div>
        </div>
    );
};

export default ContainerWithLayout;
